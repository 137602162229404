<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-select v-model="role"
                :label="$t('user.role.title') + ' *'"
                :items="roles_select"
                outlined
                hide-details
                :append-icon="'mdi-chevron-down'"
                :menu-props="{ bottom: true, offsetY: true }"
      ></v-select>
    </v-col>
<!--    <v-col cols="12" md="6">-->
<!--&lt;!&ndash;   :label="$t(`user.permission.permissions.${permission.name}`)"   &ndash;&gt;-->
<!--      <v-checkbox-->
<!--          v-for="(permission, index) in permissions"-->
<!--          :key="index"-->
<!--          v-model="selected_permissions"-->
<!--          :label="permission.display_name"-->
<!--          :value="permission.name"-->
<!--          hide-details-->
<!--          readonly-->
<!--      ></v-checkbox>-->
<!--    </v-col>-->
  </v-row>
</template>

<script>
export default {
  name: "RoleAndPermissions",
  props: {
    default_role: {
      type: String,
      default: 'manager'
    },
    roles: {
      type: Array,
      default: [],
      required: true
    },
    permissions: {
      type: Array,
      default: [],
      required: true
    }
  },
  data() {
    return {
      role: this.default_role,
      selected_permissions: [],
      role_permissions: [],
      roles_select: [],
    }
  },
  mounted() {
    this.getSelectRoles(this.roles)
    // this.role_permissions = this.getPermissionsFromRole()
    // this.selected_permissions = this.getSelectedPermissions()
    this.getEmitRole()
  },
  watch: {
    role() {
      // this.role_permissions = this.getPermissionsFromRole()
      // this.selected_permissions = this.getSelectedPermissions()
      this.getEmitRole()
    }
  },
  methods: {
    getEmitRole() {
      this.$emit('getRole', this.role)
    },
    getSelectRoles(roles) {
      for (let i = 0; i < roles.length; i++) {
        this.roles_select.push({
          text: roles[i]['display_name'],
          value: roles[i]['name']
        })
      }
    },
    getPermissionsFromRole() {
      return this.roles.find(role => role.name === this.role).permissions
    },
    getSelectedPermissions() {
      let role_permissions = this.roles.find(role => role.name === this.role).permissions
      let permissions = this.permissions
      let defer = role_permissions.filter(x => permissions.some(y => x.name === y.name))
      let res = []
      for(let i = 0; i < defer.length; i++) {
        res.push(defer[i].name)
      }
      return res
    },
  }
}
</script>
