<template>
  <v-container fluid>
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'users'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h1> {{ $t('user.create.title') }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-auto"
            elevation="2"
            color="success"
            medium
            @click="userCreate"
        >
          <v-icon size="28">mdi-content-save-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8" md="8">
        <v-card>
          <v-card-title>
            {{ $t('user.titles.personal_data') }}
          </v-card-title>
          <v-card-text>
            <v-form class="pa-4">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="autocomplete_watcher"
                      :items="items"
                      :loading="isLoading"
                      :search-input.sync="search"

                      clearable
                      hide-details
                      hide-selected
                      itemscope
                      item-text="last_name"
                      item-value="id"
                      :label="$t('user.create.employee_search_label')"
                      solo
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          {{ $t('user.create.employee_search_info') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <span>{{ item.first_name }} {{ item.last_name }}</span>
                    </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.first_name }} {{ item.last_name }} / <small style="color: #b7b7b7">{{ item.position }}</small></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.first_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('user.first_name') + ' *'"
                                :placeholder="$t('user.first_name')"
                                required
                                :error="errors.first_name!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.last_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('user.last_name') + ' *'"
                                :placeholder="$t('user.last_name')"
                                required
                                :error="errors.last_name!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.position"
                                outlined
                                hide-details
                                :label="$t('user.position') + ' *'"
                                :placeholder="$t('user.position')"
                                required
                                :error="errors.position!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="form.gender"
                            :label="$t('user.gender.title') + ' *'"
                            :items="gender_select"
                            outlined
                            disabled
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.email"
                                outlined
                                hide-details
                                type="email"
                                :label="$t('user.email') + ' *'"
                                :placeholder="$t('user.email')"
                                required
                                :error="errors.email!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.password"
                                outlined
                                hide-details
                                readonly
                                :label="$t('user.password') + ' *'"
                                :placeholder="$t('user.password')"
                                required
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-form>
          </v-card-text>
        </v-card>

        <v-card class="mt-6">
          <v-card-text>
            <RoleAndPermissions v-if="!preloader" :default_role="form.role" :permissions="permissions" :roles="roles" @getRole="getRole" />
          </v-card-text>
        </v-card>

      </v-col>
      <v-col cols="12" lg="4" md="4">
        <v-card class="mb-6">
          <v-card-title>{{ $t('user.titles.settings') }}</v-card-title>
          <v-card-text>
            <v-switch
                v-model="form.email_send"
                :label="$t('user.email_send')"
                color="primary"
            ></v-switch>
            <v-switch
                v-model="form.is_blocked"
                :label="$t('user.is_blocked')"
                color="primary"
            ></v-switch>
            <v-switch
                v-model="form.is_admin"
                :label="$t('user.is_admin')"
                color="primary"
            ></v-switch>
          </v-card-text>
        </v-card>
        <v-card class="mb-6">
          <v-card-title>{{ $t('user.titles.password') }}</v-card-title>
          <v-card-text>
            <PasswordGenerator @getPassword="getPassword"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar_error"
        top
        right
        color="red"
    >
      <template v-if="errors.uid!==undefined">
        {{ $t('user.create.errors.user_hast') }}
      </template>
      <template v-else>
        {{ $t('user.create.errors.required_fields') }}
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>
import PasswordGenerator from "@/components/PasswordGenerator";
import RoleAndPermissions from "@/components/Role/RoleAndPermissions";

export default {
  components: {PasswordGenerator, RoleAndPermissions},
  data() {
    return {
      preloader: true,
      snackbar_error: false,
      isLoading: false,
      items: [],
      search: null,
      autocomplete_watcher: null,
      permissions: [],
      roles: [],
      form: {
        identification: '',
        position: '',
        first_name: '',
        last_name: '',
        email: '',
        password: null,
        gender: 'female',
        is_admin: 0,
        is_blocked: 0,
        email_send: 0,
        role: 'user',
      },
      gender_select: [
        {
          text: this.$t('user.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('user.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('user.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('user.gender.indefinite'),
          value: 'indefinite'
        },
      ],
      errors: {},
    }
  },
  mounted() {
    this.$store.dispatch('userCreateGet').then(() => {
      this.roles = this.$store.getters.roles
      this.permissions = this.$store.getters.permissions
      this.preloader = false
    })
  },
  watch: {
    autocomplete_watcher (id) {
      if (id === null) {return}

      const item = this.items.find(item => item.id === id)

      this.form.uid = item.id
      this.form.identification = item.identification
      this.form.first_name = item.first_name
      this.form.last_name = item.last_name
      this.form.gender = item.gender
      this.form.position = item.position
      this.form.email = item.email
    },
    search (val) {
      this.isLoading = true

      this.$store.dispatch('employeeSearch', {
        search: this.search,
        search_select: 'last_name'
      }).then(() => {
        this.items = this.$store.getters.employees.data
        this.isLoading = false
      })
    }
  },
  methods: {
    getRole(value) {
      this.form.role = value
    },
    getPassword(password) {
      this.form.password = password
    },
    userCreate() {
      this.$store.dispatch('userCreate', this.form).then(() => {
        this.$router.replace({
          name: 'users'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.snackbar_error = true
            break;
        }
      })

    }
  }
}
</script>
